<template>
  <div class="user-dropdown text-right">
    <button
      type="button"
      class="btn dropdown-toggle dropdown-toggle-split border border-default-dark bg-default-dark shadow-sm no-focus no-pseudo"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img src="@/assets/icons/user-blue.png" class="icon-sm" alt="User Icon" />
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <div class="dropdown-item-text text-default-dark">{{ user.email }}</div>
      <div class="dropdown-divider border-dark"></div>
      <button
        class="dropdown-item"
        :class="{ 'text-danger': $route.name == 'site-management' }"
        @click="goTo('site-management')"
      >
        <i class="fas fa-map-marker-alt mr-2" />
        Site Management
      </button>
      <div class="dropdown-divider border-dark"></div>
      <button
        class="dropdown-item"
        :class="{ 'text-danger': $route.name == 'user-management' }"
        @click="goTo('user-management')"
      >
        <i class="fas fa-users mr-2" />
        User Management
      </button>
      <div class="dropdown-divider border-dark"></div>
      <button class="dropdown-item" @click="logout()">
        <i class="fas fa-sign-out-alt mr-2" />
        Logout
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-dropdown",
  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
    };
  },
  methods: {
    goTo(name) {
      if (name == this.$route.name) return;

      this.$router.push({
        name,
      });
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>