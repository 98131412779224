import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const homeRoute = 'map'
const authRoute = 'login'

let router = new Router({
  routes: [{
    path: '/',
    name: 'landing',
    redirect: {
      name: homeRoute
    },
    meta: {
      layout: 'empty'
    }
  }, {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true,
      layout: 'empty'
    }
  }, {
    path: '/map',
    name: 'map',
    component: () => import('../views/Map.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/MonthlyReports.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/sites/:id',
    name: 'site',
    component: () => import('../views/Site.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/user-management',
    name: 'user-management',
    component: () => import('../views/UserManagement.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/site-management',
    name: 'site-management',
    component: () => import('../views/SiteManagement.vue'),
    meta: {
      requiresAuth: true
    }
  // }, {
  //   path: '/site-management/:id',
  //   name: 'site-sales',
  //   component: () => import('../views/SiteManagement/Sales.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  }]
})

router.beforeEach((to, from, next) => {
  const tokenIsValid = Vue.Helper.validateToken()

  if (!to.matched.some(record => record.meta.dontJumpToTop)) {
    window.scrollTo(0, 0)
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!tokenIsValid) {
      next({
        path: authRoute,
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      const user = JSON.parse(localStorage.getItem('user'))

      if (to.matched.some(record => record.meta.isAdmin)) {
        if (user.isAdmin == 1) {
          next()
        } else {
          next({
            name: homeRoute
          })
        }
      } else {
        const userSites = user.sites ? user.sites.length : 0

        if (to.name == 'dashboard' && (user.username == 'central-user' || userSites > 0)) {
          next(false)

          next({
            name: 'map'
          })
        } else {
          next()
        }
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (!tokenIsValid) {
      next()
    } else {
      next({
        name: homeRoute
      })
    }
  } else {
    next()
  }
})

export default router