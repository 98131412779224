<template>
  <div id="siteDropdown" class="btn-group shadow-sm">
    <button
      type="button"
      class="btn border border-default-dark pr-4 site-dropdown-text"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        src="@/assets/icons/map-pin.png"
        class="icon-sm mr-3 d-none d-sm-inline-block"
        alt="User Icon"
      />
      <span v-if="currentSite">{{ currentSite.name }}</span>
      <span class="text-muted" v-else>Jump to site</span>
    </button>
    <button
      type="button"
      class="btn text-muted dropdown-toggle dropdown-toggle-split border border-default-dark no-focus"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="sr-only">Toggle Dropdown</span>
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <div class="dropdown-item-text px-2">
        <div class="form-group mb-0">
          <input
            type="text"
            class="form-control no-focus w-100"
            id="navSearchSite"
            ref="navSearchSite"
            placeholder="Type here to search"
            v-model="search"
          />
        </div>
      </div>
      <div class="site-dropdown-items">
        <router-link
          v-for="site in computedSites"
          :key="site._id"
          :to="{ name: 'site', params: { id: site._id } }"
          class="dropdown-item px-3 py-2"
        >
          {{ site.name }}
        </router-link>
        <div
          v-if="!isLoading && computedSites.length < 1"
          class="dropdown-item-text text-muted"
        >
          No site found
        </div>
        <div v-if="isLoading" class="dropdown-item-text text-muted">
          <div class="spinner-border"></div>
          <span class="ml-3">Loading sites...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "component-site-dropdown",
  data() {
    return {
      sites: [],
      currentSite: null,
      userRegions: [],
      userSites: [],
      isLoading: false,
      search: "",
    };
  },
  watch: {
    $route: {
      handler() {
        this.selectSite();
      },
      immediate: false,
      deep: true,
    },
  },
  computed: {
    computedSites() {
      let sites = [...this.sites].filter(
        (site) => site.region?.name !== "Central Region"
      );

      if (this.search) {
        sites = sites.filter((site) =>
          site.name.toLowerCase().includes(this.search)
        );
      }

      return sites;
    },
  },
  methods: {
    selectSite() {
      if (this.$route.name == "site" && this.$route.params.id) {
        this.currentSite = this.sites.find(
          (site) => site.id == this.$route.params.id
        );
      } else if (!this.$route.params.id) {
        this.currentSite = null;
      }
    },
    async getSites() {
      if (this.userRegions.length > 0) {
        let url = "sites?_sort=name";

        if (this.userRegions.length > 0) {
          url += `&region_in=${this.userRegions.join("&region_in=")}`;
        }

        if (this.userSites.length > 0) {
          url += `&id_in=${this.userSites.join("&id_in=")}`;
        }

        const [siteCall, siteCallErr] = await this.Helper.handle(
          this.API.get(url)
        );

        if (!siteCallErr && siteCall.status == 200) {
          this.sites = siteCall.data;

          this.selectSite();
        }
      }

      this.isLoading = false;
    },
    async getUserRegions() {
      this.isLoading = true;

      const [userCall, userCallErr] = await this.Helper.handle(
        this.API.get("users/me")
      );

      if (!userCallErr && userCall.status == 200) {
        this.userRegions = userCall.data.regions ? userCall.data.regions : [];

        this.userSites = userCall.data.sites ? userCall.data.sites : [];

        this.getSites();
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    if (this.Helper.validateToken()) {
      this.getUserRegions();
    }

    const vm = this;

    $(".dropdown-menu")
      .parent()
      .on("shown.bs.dropdown", function () {
        vm.$refs.navSearchSite.focus();
      });

    $(".dropdown-menu")
      .parent()
      .on("hidden.bs.dropdown", function () {
        vm.search = "";
      });
  },
};
</script>

<style lang="scss" scoped>
#navSearchSite {
  max-width: 100%;
  width: 200px;
}

.site-dropdown-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 200px;

  &:hover {
    color: inherit;
  }
}

.btn.dropdown-toggle {
  flex-grow: 0;
  flex-shrink: 1;
}

.site-dropdown-items {
  max-height: 68vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>