import $ from "jquery"
import Vue from 'vue'
import Moment from "moment"

class Helper {
	static splitUnderscore = (type) => {
		if (!type) return;

		return type.split("_").join(" ");
	}

	static showModal = (id) => {
		$(id).modal('show')
	}
	static hideModal = (id) => {
		$(id).modal('hide')
	}
	static showTooltip = (event) => {
		$(event.target).tooltip("show")
	}

	static sanitizeNumber = (value) => {
		return value.replace(/[^0-9\.]/g, "");
	}

	static validateCoordinate = (type, value) => {
		if (!type || !value) {
			return false;
		} else if (type == "lat") {
			return value >= -90.0 && value <= 90.0;
		} else if (type == "lng") {
			return value >= -180.0 && value <= 180.0;
		}
	}

	static mapStyle = (isSatelliteView, nameOnly) => {
		const baseUrl = "mapbox://styles/mapbox/";
		const satelliteView = "satellite-v9";
		const defaultView = "navigation-night-v1";

		return (nameOnly ? "" : baseUrl) + (isSatelliteView ? satelliteView : defaultView);
	}

	static formatGeojsonName = (name) => {
		if (name) {
			let newName = name.replace(".geojson", "");

			return newName.split("_").join(" ");
		} else {
			return "";
		}
	}

	static colors = (value) => {
		const clr = {
			aqua: "#00ffff",
			azure: "#f0ffff",
			beige: "#f5f5dc",
			black: "#000000",
			blue: "#0000ff",
			brown: "#a52a2a",
			cyan: "#00ffff",
			darkblue: "#00008b",
			darkcyan: "#008b8b",
			darkgrey: "#a9a9a9",
			darkgreen: "#006400",
			darkkhaki: "#bdb76b",
			darkmagenta: "#8b008b",
			darkolivegreen: "#556b2f",
			darkorange: "#ff8c00",
			darkorchid: "#9932cc",
			darkred: "#8b0000",
			darksalmon: "#e9967a",
			darkviolet: "#9400d3",
			fuchsia: "#ff00ff",
			gold: "#ffd700",
			green: "#008000",
			indigo: "#4b0082",
			khaki: "#f0e68c",
			lightblue: "#add8e6",
			lightcyan: "#e0ffff",
			lightgreen: "#90ee90",
			lightgrey: "#d3d3d3",
			lightpink: "#ffb6c1",
			lightyellow: "#ffffe0",
			lime: "#00ff00",
			magenta: "#ff00ff",
			maroon: "#800000",
			navy: "#000080",
			olive: "#808000",
			orange: "#ffa500",
			pink: "#ffc0cb",
			purple: "#800080",
			violet: "#800080",
			red: "#ff0000",
			silver: "#c0c0c0",
			white: "#ffffff",
			yellow: "#ffff00"
		}

		if (typeof value !== "undefined") {
			if (typeof value == "string") {
				return clr[value];
			} else if (typeof value == "number") {
				const name = value <= Object.keys(clr).length ? Object.keys(clr)[value] : Object.keys(clr)[value - Object.keys(clr).length];

				return clr[name];
			}
		} else {
			let result;

			let count = 0;

			for (let prop in clr) {
				if (Math.random() < 1 / ++count) {
					result = prop;
				}
			}

			return clr[result];
		}
	}

	static moment = (args) => {
		return Moment(args);
	}

	static formatDate = (date, withTime) => {
		if (!date) {
			return ""
		} else if (withTime) {
			if (typeof withTime === 'boolean') {
				return Moment(date).format("D MMM YYYY, h:mm A")
			} else {
				return Moment(date).format(withTime)
			}
		} else {
			return Moment(date).format("D MMM YYYY")
		}
	}

	static formatMoney = (data) => {
		if (!data) {
			return "RM 0.00"
		} else {
			return `RM ${((data * 100) / 100)
				.toFixed(2)
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
		}
	}

	static formatNumber = (data, decimal) => {
		if (!data) {
			return "0"
		} else {
			return `${((data * 100) / 100)
				.toFixed(decimal || 0)
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
		}
	}

	static formatMediaUrl = (media) => {
		if (media && media.url) {
			let url = media.url

			return url.startsWith("http") ? url : `https://${url}`
		} else {
			return false
		}
	}

	static formatCoordinate = (coord) => {
		if (coord && typeof coord == "object" && coord.length == 2) {
			return `${parseFloat(coord[0]).toFixed(6)}, ${parseFloat(coord[1]).toFixed(6)}`
		}
		if (coord && !isNaN(coord)) {
			return `${parseFloat(coord).toFixed(6)}`
		} else {
			return ""
		}
	}

	static formatAddress = (address, city, state) => {
		let result = []

		if (address) {
			result.push(address)
		}

		if (city) {
			result.push(city)
		}

		if (state) {
			result.push(state)
		}

		return result.join(", ")
	}

	static validateEmail = (email) => {
		let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

		if (!pattern.test(email)) {
			return false;
		}

		return true;
	}

	static validatePassword = (password) => {
		let pattern = new RegExp(/^(?=.*[\W_])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}$/);

		if (!pattern.test(password)) {
			return false;
		}

		return true;
	}

	static validateToken = () => {
		const token = localStorage.getItem("token");

		if (!token) {
			localStorage.removeItem("token");

			return false;
		}

		let tokenSplit = token.split(".");

		if (tokenSplit.length != 3) {
			localStorage.removeItem("token");

			return false;
		} else {
			let decodedHeader = atob(tokenSplit[0]);

			if (decodedHeader && JSON.parse(decodedHeader).typ == "JWT") {
				return true;
			} else {
				localStorage.removeItem("token");

				return false;
			}
		}
	}

	static handle = (promise) => {
		return promise
			.then(data => ([data, undefined]))
			.catch(error => Promise.resolve([undefined, error]));
	}
}

export default {
	utils: Helper,
	install: () => {
		Vue.prototype.Helper = Helper
		Vue.Helper = Helper
	}
}